/* global hemma_ajax_obj */

jQuery(($) => {
    $('.hemma-add-to-cart, .item-add-to-cart').on('click', (event) => {
        $.ajax({
            type: 'POST',
            url: hemma_ajax_obj.ajax_url,
            data: {
                action: 'hemma_add_to_cart_action',
                product_id: $(event.target).closest('.hemma-add-to-cart, .item-add-to-cart').attr('data-product-id'),
                nonce: hemma_ajax_obj.nonce,
            },
            success: (response) => {
                if (response.success) {
                    $('#total-in-cart').text(response.data);

                    $('#hemma-add-success').addClass('active');

                    setTimeout(() => {
                        $('#hemma-add-success').removeClass('active');
                    }, 5000);
                }
            },
        });
    });
});
